import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"

export default () => (
  <StaticQuery
    query={graphql`
      query imageTechniquesEnQuery {
        file(relativePath: { eq: "microchirurgie.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Dr. Roure uses recent and minimally invasive techniques such as microsurgery"
        css={css`
          margin-top: 6px;
        `}
      />
    )}
  />
)
