import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"

export default () => (
  <StaticQuery
    query={graphql`
      query imageSurMesureEnquery {
        file(
          relativePath: {
            eq: "approche-sur-mesure-chirurgie-membre-superieur.jpg"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="A tailor-made approach to orthopedic surgery of the arm and hand"
        css={css`
          margin-top: 6px;
        `}
      />
    )}
  />
)
